import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Features">
    <section className="section-feature">

      <div className="info-msg">
        <p>
          शिक्षित भारत समृद्ध भारत, इसकी कल्पना करते हुए, डिजिटल इंडिया एवं मेक इन इंडिया के क्षेत्र में एक और कदम जोड़ते हुए,<br/>
          शिक्षकों के द्वारा अपने सहकर्मी बहन एवं बंधुओं के लिए प्रस्तुत है, <strong>सरल एवं सतत ई-मूल्यांकन</strong><br/>
          इसका उपयोग सभी, govt, एवं private school की कक्षा 1-12 को पढ़ाने वाले शिक्षक ले सकते है |<br/>
          इस सुविधा में शिक्षकों की कठिनाईयों को दूर करने एवं समय व धन के अपव्यय को रोकने की ओर विशेष ध्यान दिया गया है |<br/>
          यह एक <strong>निशुल्क एवं स्वैच्छिक सेवा</strong> हैं, जिसका उद्देश्य शिक्षको के कार्यो को सरल व आसान बनाना है, न की उनके कार्यो की निगरानी करना|
        </p>

        <h4>विशेषताएँ -</h4>
        <mat-card>
          <mat-card-header>
            <mat-card-title><h5>प्लेटफार्म</h5></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <dl>
              <dt>Comprehensive Features for Desktop</dt>
              <dd>ऑनलाइन वेब एप्प</dd>
              <dt>A Powerful Platform for School/Teachers on the Go</dt>
              <dd>ऑनलाइन एवं ऑफलाइन गूगल एंड्राइड एप्प</dd>
              <dd>ऑनलाइन एवं ऑफलाइन एप्पल आईओएस(iOS) एप्प</dd>
            </dl>
          </mat-card-content>
          <mat-card-actions>
            <a mat-button href="https://play.google.com/store/apps/details?id=me.parakh.edu">Find out more »</a>
          </mat-card-actions>
        </mat-card>

        <br/>

        <mat-card>
          <mat-card-header>
            <h5>भाषाएँ</h5>
          </mat-card-header>
          <mat-card-content>
            हिंदी | English | ગુજરાતી | मराठी | తెలుగు
          </mat-card-content>
        </mat-card>

        <br/>

        <mat-card>
          <mat-card-header>
            <h5>PDF रिपोर्ट</h5>
          </mat-card-header>
          <mat-card-content>
            <dl>
              <dt>अंक-सूची</dt>
              <dd>विद्यार्थी के छायाचित्र वाली कंप्यूटर प्रिंट मार्कशीट</dd>
            </dl>
            <dl>
              <dt>अन्य रिपोर्ट्स</dt>
              <dd>परिशिष्ट 6/7/8(पत्रक एवं गोसवारा), परिशिष्ट 9(प्रारंभिक शिक्षा पूर्णता प्रमाण पत्र), स्थानांतरण प्रमाण पत्र(tc) </dd>
            </dl>
          </mat-card-content>
        </mat-card>

        <hr/>

        सरल मूल्यांकन विधि का उपयोग <br/>
        1. ऑनलाइन वेबसाइट<br/>
        2. या एंड्राइड एप्प <br/>
        3. या उपरोक्त दोनों  के माध्यम से आप अपनी सुविधा के अनुसार कर सकते है |<br/>
        वेबसाइट का उपयोग ऑनलाइन/ इंटरनेट के माध्यम से   एवं एप्प का उपयोग ऑनलाइन व ऑफलाइन, बिना इंटरनेट के भी करा जा सकता है । उपरोक्त दोनों विधियों को पासवर्ड द्वारा सुरक्षित करा गया है, जिससे केवल अधिकृत शिक्षक ही इसका उपयोग कर सकते है |<br/>
        <br/>
        वेबसाइट एवं एप्प की विशेषताए इस प्रकार से है -<br/>
        - केंद्र एवं राज्य शिक्षा के नियमों के अनुसार मूल्यांकन एवं विद्यार्थी के फोटो वाली कंप्यूटर मार्क्स-शीट | <br/>
        - Internationalization -  शिक्षक अपनी सुविधा के अनुसार भाषा का चयन कर सकते है | <br/>
        - Localization  - विद्यालय के माध्यम के अनुसार हिन्दी या इंग्लिश में रिपोर्ट जनरेट कर सकते है |<br/>
        - No duplicate entry or effort -   पोर्टल पर कोई भी जानकारी केवल एक बार ही इनपुट करी जाती है | सत्र समाप्ति पर विद्यार्थी की कक्षा या विद्यालय में परिवर्तन, क्रमशः कक्षा व विद्यालय   स्थानांतरण क्लिक मात्र से करा जाता है,<br/>
        - e-Student Attendance - मोबाइल से विद्यार्थी की प्रतिदिन उपस्थिति, कही भी कभी भी, बिना इंटरनेट के |   इसके उपयोग से प्रतिदिन शिक्षक 3-4 min का समय बचाते है अर्थात,   पुरे सत्र में 10-12 घंटे एवं प्रत्येक कक्षा से एक उपस्थिति रजिस्टर की बचत होती है<br/>
        - Student Portfolio - कुछ शिक्षक इसे छात्र की जन्म कुंडली भी कहते है | पोर्टफोलियो में आप विद्यार्थी का शैक्षिक, उपस्थिति, एवं स्वास्थ्य, का तुलनात्मक अध्यन, ग्राफ एवं सांख्यिकी के माध्यम से कर सकते है |<br/>
        - e-MarksSheet - हैंड प्रिंट मार्कशीट के बजाये अब विद्यार्थी स्वयं के छायाचित्र वाली, कंप्यूटर प्रिंट मार्कशीट प्राप्त कर सकेंगे और मार्क्सशीट गुम हो जाने पर तुरंत पुनः प्राप्त कर सकेंगे |
        साथ ही आप परिशिष्ट 6/7/8, प्रारंभिक शिक्षा पूर्णता प्रमाण पत्र, tc आदि केवल क्लिक मात्र से प्राप्त कर सकते है, इस विधि से शिक्षक प्रत्येक वर्ष १-२ सप्ताह का समय बचाते है एवं इसमे त्रुटिया भी न के बराबर होती है |<br/>
        <br/>
        अन्य नये फीचर, या फिर टूल में हुए परिवर्तन की जानकारी पाने के लिए पोर्टल के facebook, एवं google+ पेज को  लाइक करे, साथ ही साथ youtube चैनल को सब्सक्राइब करे एवं आप हमें twitter पर भी फॉलो कर सकते है |<br/>
        <br/>
        अंत में, मैं आप सभी से अनुरोध करना चाहुगा, कृपया आप अपना किसी भी प्रकार का सुझाव हमसे सोशल मीडिया या ईमेल द्वारा शेयर करे,
        हो सकता है आप जिसे छोटा सुझाव समझ रहे है, वो  हमारे या अन्य बहन-बंधुओ के लिए महत्वपूर्ण सिद्ध हो<br/>
        <br/>
        यदि आप हमारे प्रयासों को पसंद करते है, तब प्ले स्टोर से एप्प को डाउनलोड कर उपयोग करे, साथ ही अपने रिव्यु, रेटिंग एवं +1 देकर हमारे उत्साह को बढ़ाये |<br/>

        आपका हमें सुनने एवं योजना को समझने के लिए धन्यवाद <br/>
        आशा करता हु आप वालंटियर के रूप में,  हमारा एवं अन्य बहन-बंधुओं का मार्गदर्शन करेंगे, जय हिन्द !!<br/>

      </div>
      <br/>


      <br/>
      <br/>


    </section>
  </Layout>
);

export default Page;


/*

      <!--
    <section className="row product">

      <div className="col-md-6 col-xs-12">

        <div className="well well-large text-center">
          <p className="fa fa-mobile"></p>
          <h4 className="thin-sub">Mobile App</h4>
          <p>
            A Powerful Platform for School/Teachers on the Go
            &lt;!&ndash;
        India is going mobile. More people today are accessing the internet on their phone than ever before. The phone is your window to the school,
            &ndash;&gt;
          </p>
          <a href="https://play.google.com/store/apps/details?id=me.parakh.edu" data-analytics-on="click" data-analytics-event="StoreAndroid">
            <span className="text-center more">Find out more »</span>
          </a>
        </div>
      </div>

      <div className="col-md-6 col-xs-12">
        <div className="well well-large text-center">
          <p className="fa fa-desktop"></p>
          <h4 className="thin-sub">Web App</h4>
          <p>
            Comprehensive Features for Desktop
          </p>
          <a target="_blank" href="http://web.parakh.me">
            <span className="text-center more">Find out more »</span>
          </a>
        </div>
      </div>

    </section>
  -->
      <!--
      <div className="centered">
        <div className="promo-mobile">
          <iframe width="400" height="225" src="https://www.youtube.com/embed/eKro0PchJh4" frameborder="0" allowFullScreen></iframe>
        </div>
      </div>
       -->


      <!--
      <div className="how-to">
      शिक्षित भारत समृद्ध भारत, इसकी कल्पना करते हुए,<br/>
      हम आपके समक्ष प्रस्तुत करते है, शिक्षक समय बचाओ योजना का पहला चरण "सरल एवं सतत मूल्यांकन"<br/>
      <br/>
      इस योजना का लाभ म. प्र. के सभी सरकारी(state govt) एवं, निजी गैर सहायता प्राप्त(private aided), या निजी सहायता प्राप्त(private unaided) की प्लेस्कूल से ८वि  तक की कक्षा लेने वाले शिक्षक बहन-बंधू ले सकते है |<br/>
      <br/>
      सरल मूल्यांकन विधि का उपयोग <br/>
      1. ऑनलाइन वेबसाइट या <br/>
      2. एंड्राइड एप्प या <br/>
      3. उपरोक्त दोनों <br/>
      के माध्यम से आप अपनी सुविधा के अनुसार कर सकते है |<br/>
      <br/>
      वेबसाइट का उपयोग ऑनलाइन/ इंटरनेट के माध्यम से एवं एप्प का उपयोग ऑनलाइन व ऑफलाइन, बिना इंटरनेट के भी करा जा सकता है । उपरोक्त दोनों विधियों को पासवर्ड द्वारा सुरक्षित करा गया है, जिससे केवल अधिकृत शिक्षक ही इसका उपयोग कर सके | <br/>
      <br/>
      दूसरी विशेषता, परख एजुकेशन पोर्टल पर कोई भी जानकारी केवल, और केवल एक बार ही इनपुट करी जाती है, उधारण के लिए आप जो छात्र सम्बंधित जानकारी कागज पर या कंप्यूटर पर बनाते है, इसमे अधिकांशतः आप छात्र का नाम, जाती, माता पिता का नाम हर बार लिखते या टाइप करते है, परन्तु परख पर छात्र, शिक्षक या विद्यालय से सम्बंधित जानकारी केवल एक ही बार दी जाती है, छात्र के एक कक्षा से दूसरी कक्षा या एक विद्यालय से  दूसरे विद्यालय में जाने पर भी, दुबारा एंटर नहीं करी जाती है ।<br/>
      <br/>
      छात्र का मूल्यांकन हो या स्वयं या विद्यालय से सम्बंधित जानकारी, आप इसे कही भी कभी भी एप्प में एंटर कर सकते है, और जब भी आप इंटरनेट क्षेत्र में आते है, तब एप्प लांच होने पर, इसे एप्प सर्वर पर आटोमेटिक पुश करती है । साथ ही शिक्षण-सत्र के समाप्त होने पर एप्प या वेबसाइट द्वारा छात्र मार्कशीट एवं अन्य पत्रक को पीडीएफ फाइल में डाउनलोड कर सकते है, और कही भी प्रिंट करवा सकते है <br/>
      <br/>
      <strong>विधि -</strong><br/>
      <strong>- एप्प डाउनलोड एंड इनस्टॉल </strong><br/>
      मोबाइल एप्प का उपयोग करने के लिए सर्वप्रथम आप परख एजुकेशन पोर्टल एप्प को किसी स्मार्ट फ़ोन या टैब में डाउनलोड कर ले (आप के स्वयं का फ़ोन या टैब न होने पर, आप अपने परिजनों या मित्रो के फ़ोन या टैब का भी प्रयोग  कर सकते है) <br/>
      <br/>
      परख एजुकेशन पोर्टल एप्प या वेबसाइट में प्रवेश करने के लिए आप अपने मोबाइल नंबर एवं पासवर्ड का उपयोग करे । यदि किसी कारणवश आप उपना पासवर्ड भूल गए है या आपको प्राप्त नहीं हुआ है,  तब "फॉरगॉट पासवर्ड " के इसे पुनः प्राप्त करे ।<br/>
      <br/>
      यदि आप का परख एजुकेशन पोर्टल पर रजिस्ट्रेशन नहीं हुआ है तब आप एप्प द्वारा स्वयं को आमंत्रित (self invite) कर, या किसी अन्य एप्प उपयोगकर्ता शिक्षक,   जन-शिक्षक, बी आर सी, से आग्रह कर स्वयं को पोर्टल पर आमत्रित करने के लिए कहे ।<br/>
      </div>
      <br/>
      <br/>
      <p>We offer following powerful features:</p>
      <ul>
          <li>This is a Complete "Nishulk Abhiyan"</li>
          <li>Real-Time Attendance Monitoring</li>
          <li>Comprehensive Reporting</li>
          <li>Access from desktop, iOS (including iPhone and iPad), Android and Browser Extension.</li>
          <li>Centralized Analytics & Reporting</li>
      </ul>

      -->
 */